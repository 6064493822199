<template>
  <div class="navigation-mobile">
    <div id="top-container">
      <router-link to="/" class="carbo-logo"
      ><img src="~@/assets/logo_carbo_brake.svg" alt=""
      /></router-link>
      <LanguageSwitch />
      <div
          id="nav-icon"
          :class="{ open: isOpened === true }"
          @click="iconTrigger()"
      >
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>

    <div id="nav-overlay">
      <div id="nav-content">
        <!--        <router-link @click.native="closeMenu()" to="/">{{
                  $t("home-title")
                }}</router-link>-->

        <router-link @click.native="closeMenu()" to="/projects">{{
            $t("projects-title-uppercase")
          }}</router-link>

        <div class="nav-title">
          <strong>{{$t("nav-products")}}</strong>
        </div>

        <router-link @click.native="closeMenu()" to="/calipers">{{
            $t("calipers-title")
          }}</router-link>
        <div id="nav-products">
          <router-link @click.native="closeMenu()" to="/a370" class="child">{{
              $t("a370-title")
            }}</router-link>
          <router-link @click.native="closeMenu()" to="/a400" class="child">{{
              $t("a400-title")
            }}</router-link>
          <router-link @click.native="closeMenu()" to="/a410" class="child">{{
              $t("a410-title")
            }}</router-link>
          <router-link @click.native="closeMenu()" to="/a420" class="child">{{
              $t("a420-title")
            }}</router-link>
        </div>
        <router-link @click.native="closeMenu()" to="/rotors">{{
            $t("rotors-title")
          }}</router-link>

        <router-link @click.native="closeMenu()" to="/pads">{{
            $t("pads-title")
          }}</router-link>

        <router-link @click.native="closeMenu()" to="/cooling">{{
            $t("cooling-title")
          }}</router-link>

        <router-link @click.native="closeMenu()" to="/epb">{{
            $t("epb-title")
          }}</router-link>
        <router-link @click.native="closeMenu()" to="/oes">{{
            $t("oes-title")
          }}</router-link>

        <!--        <router-link
                  @click.native="closeMenu()" class="child" to="/upgrade-kit">{{
                    $t("upgrade-kit-title")
                  }}</router-link>-->

        <a title="Carbobrake Shop" href="/shop">SHOP</a>


        <div class="nav-title"><strong>{{
            $t("nav-company")
          }}</strong></div>

        <router-link @click.native="closeMenu()" to="/history" >{{
            $t("history-title")
          }}</router-link>

        <router-link @click.native="closeMenu()" to="/mission-vision" >{{
            $t("mission-vision-title")
          }}</router-link>

        <router-link @click.native="closeMenu()" to="/contact-us" >{{
            $t("contact-us-title")
          }}</router-link>

        <router-link @click.native="closeMenu()" to="/official-dealers" >{{
            $t("dealers-title")
          }}</router-link>

        <ul class="social-menu">
          <li>
            <a
                title="Sicom on Facebook"
                href="https://www.facebook.com/CARBObrake/"
                target="_blank"
            >
              <font-awesome-icon :icon="['fab', 'facebook-square']" size="2x"
              /></a>
          </li>
          <li>
            <a
                title="Sicom on Linkedin"
                href="https://www.linkedin.com/company/sicom-automotive?trk=ppro_cprof"
                target="_blank"
            ><font-awesome-icon :icon="['fab', 'linkedin']" size="2x" />
            </a>
          </li>
          <li>
            <a
                title="Sicom on Instagram"
                href="https://www.instagram.com/carbobrake/"
                target="_blank"
            ><font-awesome-icon :icon="['fab', 'instagram']" size="2x" />
            </a>
          </li>
          <li></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import LanguageSwitch from "../components/LanguageSwitch.vue";
import { TimelineMax, Power3 } from "gsap";
export default {
  name: "Footer",
  components: {
    LanguageSwitch,
  },
  data() {
    return {
      timeline: "",
      vertNav: "",
      navTriggerWrapper: "",
      navTrigger: "",
      navIcon: "",
      vertMenu: "",
      logo: "",
      isOpened: false,
      screenWidth: "",
    };
  },
  methods: {
    openMenu() {
      this.timeline.play().duration(1);
      this.isOpened = true;
    },
    closeMenu() {
      this.timeline.reverse().duration(1);
      this.isOpened = false;
    },
    iconTrigger() {
      if (this.timeline.reversed()) {
        this.timeline.play().duration(1);
        this.isOpened = true;
      } else {
        this.timeline.reverse().duration(1);
        this.isOpened = false;
      }
    },
  },
  mounted() {
    this.navIcon = document.getElementById("nav-icon");
    this.vertMenu = document.getElementById("nav-overlay");

    this.timeline = new TimelineMax({
      paused: !0,
      reversed: !0,
    });

    this.timeline.fromTo(
        this.vertMenu,
        0.5,
        {
          display: "none",
          pointerEvents: "none",
          opacity: 0,
        },
        {
          pointerEvents: "all",
          display: "block",
          opacity: 1,
          ease: Power3.easeIn,
        }
    );
    /* this.timeline.fromTo(
      this.vertMenu,
      0.1,
      {
        opacity: 0
      },
      {
        opacity: 1,
        ease: Power3.easeIn
      }
    ) */
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss"></style>
