import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loading: false,
    footerVisible: false,
    cookiesAccepted:false,
    counter: 0,
    percentage: 0
  },
  mutations: {
    setLoading(state, value) {
      state.loading = Boolean(value)
    },

    setCookies(state, value) {
      state.cookiesAccepted = Boolean(value)
    },

    setFooterVisible(state) {
      state.footerVisible = true
    },
    setCounter(state, data) {
      state.counter = Number(data.counter)
      state.percentage = parseInt((100* data.counter)/data.total,10)
    }
  },
  actions: {
    LOAD_IMAGES({commit,state}, images) {
      return new Promise(resolve => {
        commit('setCounter', {counter:0, total:images.length})
        commit('setLoading', true)
        images.forEach(img => {
          const i = new Image()
          i.onload = () => {
            commit('setCounter',  {counter:state.counter +1, total:images.length})
            if (state.counter >= images.length) {
              commit('setCounter', {counter:0, total:images.length})
              commit('setLoading', false)
              resolve()
            }
          }
          i.src = img
        })
      })

    },
    SET_COOKIES(commit, cookieState) {
      return new Promise(resolve => {
        commit('setCookies', cookieState)
        resolve()
      })

    }
  }
})
